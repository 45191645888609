import {
  ICompanyHabit,
  ICompanyMemberTeam,
  ICompanyMemberTeamOptionalTeam,
  ICompanySkill,
} from '../common/models/Company'
import {
  CompetencyType,
  IExternalFeedback,
  IFeedback,
  IFeedbackCompetency,
  IFeedbackCompetencyPure,
} from '../common/models/Feedback'
import {
  OnboardingChapterType,
  OnboardingContactType,
  OnboardingContentType,
} from '../common/models/OnboardingTemplates'

export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined
}

export const notEmptyTeam = (
  value: ICompanyMemberTeamOptionalTeam
): value is ICompanyMemberTeam => {
  return value.team !== undefined
}

export const isSkill = (
  competency: ICompanySkill | ICompanyHabit | IFeedbackCompetencyPure,
  pureCompetency: IFeedbackCompetencyPure
): competency is ICompanySkill => {
  return pureCompetency.competency === CompetencyType.skill
}

export const isExternalFeedback = (
  feedback: IFeedback | IExternalFeedback
): feedback is IExternalFeedback => {
  return (feedback as IExternalFeedback).memberCompanyName !== undefined
}

export function isIFeedbackCompetency(
  competency: IFeedbackCompetencyPure | IFeedbackCompetency
): competency is IFeedbackCompetency {
  return (competency as IFeedbackCompetency).name !== undefined
}

export const isValidChapterType = (
  type: string
): type is OnboardingChapterType => {
  return Object.values(OnboardingChapterType).includes(
    type as OnboardingChapterType
  )
}

export const isValidContact = (
  contact: string
): contact is OnboardingContactType => {
  return Object.values(OnboardingContactType).includes(
    contact as OnboardingContactType
  )
}

export const isValidContentType = (
  contentType: string
): contentType is OnboardingContentType => {
  return Object.values(OnboardingContentType).includes(
    contentType as OnboardingContentType
  )
}
