export enum OnboardingChapterType {
  CULTURE = 'culture',
  SKILLS = 'skills',
  OFFICE = 'office',
}

export enum OnboardingContentType {
  TEXT_FIELD = 'Text Field',
  SUBTITLE = 'Subtitle',
  LINK_BUTTON = 'Link Button',
  IMAGE = 'Image',
  ATTACHMENT = 'Attachment',
}

export enum OnboardingContactType {
  THEIR_LEAD = 'Their lead',
  THEIR_BUDDY = 'Their buddy',
  THEIR_GUIDE = 'Their guide',
  ALL_TEAM_MEMBERS = 'All team members',
  SPECIFIC_MEMBER = 'Specific member',
  DECIDE_LATER = 'Decide later',
  ADD_NO_CONTACT = 'No contact',
}

export interface IOnboardingTemplatePure {
  _id?: any
  companyId: string
  name: string
  chapters: IOnboardingTemplateChapterPure[]
  dateCreated: Date
  lastEdited: Date
}

export interface IOnboardingTemplateChapterPure {
  _id?: any
  name: string
  type: OnboardingChapterType
  activities: IOnboardingTemplateActivityPure[]
}

export interface IOnboardingTemplateActivityPure {
  _id?: any
  title: string
  content: IOnboardingTemplateActivityContentPure[]
  contact: IOnboardingTemplateActivityContactPure
}

export interface IOnboardingTemplateActivityContentPure {
  type: OnboardingContentType
  text: string
  link?: string
}

export interface IOnboardingTemplateActivityContactPure {
  type: OnboardingContactType
  contactId?: string
}

// Interfaces for front-end and back-end communication
export type IEditOnboardingTemplateName = Pick<IOnboardingTemplatePure, 'name'>

export type IOnboardingTemplateCompanyOverview = Pick<
  IOnboardingTemplatePure,
  '_id' | 'name' | 'lastEdited'
> & {
  chapterActivitiesCount: number
  onboardingUsersCount: number
}

export type IOnboardingTemplateActivity = Omit<
  IOnboardingTemplateActivityPure,
  'content'
>

export type IOnboardingTemplateOverviewChapter = Omit<
  IOnboardingTemplateChapterPure,
  'activities'
> & {
  activities: IOnboardingTemplateActivity[]
}
export type IOnboardingTemplateOverview = Omit<
  IOnboardingTemplatePure,
  'chapters'
> & {
  chapters: IOnboardingTemplateOverviewChapter[]
}
