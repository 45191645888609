import { ITab } from './../../components/TabsWithContent'
import { ICompanyHabit } from './../models/Company'
import { ICompanyPure, ICompanyTerm, ICompanyLevel } from '../models/Company'

export const getCurrentTerm = (
  company: ICompanyPure
): ICompanyTerm | undefined => {
  return company.termsInformation.terms.find(
    (term) => term.termNumber === company.state.currentTermNumber
  )
}

export const getHabitTabs = (
  habit: ICompanyHabit,
  memberLevel?: ICompanyLevel
): ITab[] => {
  return habit.allLevelsDescription
    ? [
        {
          richText: habit.allLevelsDescription,
          openByDefault: true,
        },
      ]
    : habit.levels
        .map((level) => {
          return {
            tag: level.level?.name,
            richText: level.description,
            openByDefault:
              level.levelId === memberLevel?.cultureLevelId ||
              level.level?.name === memberLevel?.name,
            rank: level.level?.rank,
          }
        })
        .sort((a, b) => a.rank - b.rank)
}
